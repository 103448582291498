/**
 * @file Anything that can run on every page.
 */

import autosize from 'autosize';

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	import('../components/header/header').then(({ default: vHeader }) => {
		vHeader();
	});
	import('../components/nav-trigger/nav-trigger').then(
		({ default: vNavTrigger }) => {
			vNavTrigger();
		}
	);
	import('../components/mobile-nav/mobile-nav').then(
		({ default: vMobileNav }) => {
			vMobileNav();
		}
	);
	import('../components/hero-banner/hero-banner').then(
		({ default: vHeroBanner }) => {
			vHeroBanner();
		}
	);
	import('../components/project-slider/project-slider').then(
		({ default: vProjectSlider }) => {
			vProjectSlider();
		}
	);
	import('../components/post-list-gallery/post-list-gallery').then(
		({ default: vPostListGallery }) => {
			vPostListGallery();
		}
	);

	if (document.querySelector('.v-simple-popup')) {
		import('../components/simple-popup/simple-popup').then(
			({ default: vSimplePopup }) => {
				vSimplePopup();
			}
		);
	}

	const selects = document.querySelectorAll('select');
	[...selects].forEach((select) => {
		const selectParent = select.parentNode;
		if (selectParent.classList.contains('v-form__select')) {
			return;
		}

		const wrapper = document.createElement('span');
		const arrow = document.createElement('span');
		wrapper.classList.add('v-form__select');
		arrow.classList.add('v-form__select-arrow');
		selectParent.insertBefore(wrapper, select);
		wrapper.appendChild(select);
		wrapper.appendChild(arrow);
		select.classList.add('v-form__select-field');
	});

	const submitFields = document.querySelectorAll('.v-form__field--submit');
	[...submitFields].forEach((field) => {
		const form = field.closest('.wpcf7-form');
		const responseBox = form.querySelector('.wpcf7-response-output');
		field.appendChild(responseBox);
	});

	const floatingLabelWraps = document.querySelectorAll(
		'.v-form__floating-label-wrap'
	);
	[...floatingLabelWraps].forEach((wrap) => {
		const label = wrap.querySelector('.v-form__floating-label');
		const input = wrap.querySelector('input, select, textarea');

		input.addEventListener('focus', () => {
			label.classList.add('v-form__floating-label--float');
		});

		input.addEventListener('blur', () => {
			if (input.value === '') {
				label.classList.remove('v-form__floating-label--float');
			}
		});
	});

	autosize(document.querySelectorAll('textarea'));

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			const { listen } = await import('quicklink');

			listen({
				ignores: [
					(url) => {
						const cleanUrl = url.replace(/#.*/, '');
						return cleanUrl === window.location.href;
					},
					/.*\/wp-admin\/.*/,
				],
			});
		}
	}

	if (document.readyState === 'complete') {
		// onWindowLoad();
	} else {
		// window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', vGlobal);
}
